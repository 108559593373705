import React from "react";

const UnderLastVideo = () => (
	<>
    <br/><hr/><hr/>
    <h2 style={{ color: `#00C185`, fontFamily: `Maitree, sans-serif`, display: `block`, lineHeight: `44px`}} >คุณมาถึงส่วนท้ายของฟีดโพสต์นี้แล้ว  หากคุณเลื่อนลงไปอีก คุณจะไปที่ด้านบนของฟีดนี้</h2>
    <hr/><hr/><hr/><hr/><hr/><hr/><hr/><hr/><hr/><hr/><hr/><hr/><hr/><hr/><hr/><hr/><hr/><hr/><hr/><hr/><hr/><hr/><hr/><hr/><hr/><hr/><hr/><hr/><hr/><hr/><hr/><hr/>
	</>
)

export default UnderLastVideo;
